@media (max-width: 768px) {
  .section{
    display: flex;
    flex-direction: column;

    .section-a{
      margin: 0;
      padding: 0;
      width: 100%;
    }
    .section-b{
      margin: 0;
      padding: 0;
      width: 100%;
    }
  }
}
